@import '../../../styles/index';

.base {
  align-items: center;
  background-color: $color-black--05;
  color: $color-black--100;
  display: none;
  font-size: $font-size-14;
  gap: 1rem;
  height: 3rem;
  justify-content: flex-end;
  padding: 0 1.25rem;

  &.is-inverted {
    background-color: rgba($color-white--100, 0.16);
    color: $color-white--100;
  }

  @include media-from(lg) {
    display: flex;
  }
}

.divider {
  background-color: currentColor;
  height: 1rem;
  width: 1px;
}

.link {
  color: currentColor;
  cursor: pointer;
  position: relative;

  &:hover::after,
  &.is-active::after {
    background-color: currentColor;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.full-width-container {
  background-color: $color-black--05;
  color: $color-black--100;

  &.is-inverted {
    background-color: rgba($color-white--100, 0.16);
    color: $color-white--100;
  }

  .base {
    background-color: transparent;
    color: currentColor;
    margin: 0 auto;
    max-width: 1440px;
  }
}
