@import '../../styles/index';

.base {
  position: relative;
  user-select: none;
  width: 100%;
  z-index: $index-global-header;

  &.is-fixed {
    /*
      We need this workaround to solve an overscroll issue
      in Firefox Mobile, caused by position: sticky on the header.
      Because position: fixed solves this problem but might cause
      new problems outside of Firefox, we only apply this in FF.
    */

    @supports (-moz-appearance: none) {
      position: fixed !important;
    }
  }

  &.is-full-width {
    margin-left: 0;
    width: 100%;
  }

  &.is-inverted {
    background-image: linear-gradient(
      to bottom,
      rgba($color-black--100, 0.2),
      rgba($color-black--100, 0)
    );
  }

  &.is-sticky {
    position: sticky;
    top: var(--header-sticky-y);
  }

  [data-layout-background='white'] &:not(.is-inverted, .is-full-width) {
    @include media-from(lg) {
      margin-left: -1.25rem;
      width: calc(100% + 2.5rem);
    }
  }
}

.logo {
  color: inherit;
  display: flex;
}

.click-outside-handler {
  display: none;
  pointer-events: none;
}
