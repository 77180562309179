@import '../../../styles/index';

.base {
  align-items: center;
  background-color: $color-white--100;
  border-top: 1px solid $color-black--10;
  display: flex;
  height: 4rem;
  padding: 0 1rem;
  position: relative;
  transition:
    all $duration-default,
    position 0s;

  @include media-to(lg) {
    &::after {
      background-color: rgba($color-black--100, 0.5);
      content: '';
      display: block;
      height: calc(100vh - 4rem);
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 4rem;
      transition: opacity $duration-long;
      width: 100%;
      z-index: -1;
    }

    &.has-backdrop::after {
      opacity: 1;
    }
  }

  &.is-inverted {
    background-color: transparent;
    border-top-color: $color-white--100;
    color: $color-white--100;
  }

  @include media-from(lg) {
    padding: 0 1.25rem;
  }
}

.dropdown-activator {
  display: flex;
  margin-right: 1rem;

  @include media-from(lg) {
    margin-right: 1.25rem;
  }
}

.links-host {
  list-style-type: none;
  margin-left: auto;
  max-width: 100%;
  overflow: hidden;
}

.links {
  align-items: center;
  display: flex;
  gap: 2rem;
  list-style: none;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  padding: 0;

  &.is-collapsed {
    align-items: stretch;
    background-color: $color-white--100;
    color: $color-black--100;
    flex-direction: column;
    gap: 0;
    left: 0;
    padding: 0 1rem 1rem 1rem;
    position: absolute;
    top: calc(4rem - 1px);
    width: 100%;

    @include media-from(lg) {
      border-radius: 0.25rem;
      box-shadow: $shadow-small;
      left: 1.25rem;
      padding: 0.5rem;
      top: calc(3.5rem - 1px);
      width: auto;
    }
  }

  &.is-measured {
    height: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.link-container {
  flex-shrink: 0;

  .is-collapsed & {
    height: 3rem;

    @include media-from(lg) {
      height: 2.5rem;
    }
  }

  .is-collapsed & + & {
    @include media-to(lg) {
      border-top: 1px solid $color-black--10;
    }
  }
}

.link {
  color: currentColor;
  cursor: pointer;
  font-size: $font-size-16;
  position: relative;
  transition:
    background-color $duration-short,
    color $duration-short;
  white-space: nowrap;

  &:focus-visible {
    outline: 2px solid $color-surrogate--100;
  }

  &:hover {
    color: $color-surrogate--100;
  }

  &.is-active::after {
    background-color: currentColor;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .is-collapsed & {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 100%;
    width: 100%;

    &::after {
      display: none;
    }

    &.is-active {
      color: $color-surrogate--100;
    }

    @include media-from(lg) {
      border-radius: 0.25rem;
      padding: 0 0.75rem;
      width: auto;

      &:hover {
        background-color: $color-surrogate--05;
        color: $color-surrogate--100;
      }
    }
  }

  .is-inverted :not(.is-collapsed) & {
    transition: color $duration-short;

    &:hover {
      color: rgba($color-white--100, 0.5);
    }
  }

  a {
    color: inherit;
  }
}

.slot-container {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  gap: 1rem;
  margin-left: 1rem;

  @include media-from(lg) {
    gap: 1.5rem;
    margin-left: 1.25rem;
  }
}

.full-width-container {
  background-color: $color-white--100;
  border-top: 1px solid $color-black--10;
  transition: all $duration-default;

  &.is-inverted {
    background-color: transparent;
    border-top-color: $color-white--100;
    color: $color-white--100;
  }

  .base {
    background-color: transparent;
    border-top: none;
    margin: 0 auto;
    max-width: 1440px;

    &.is-fluid {
      max-width: unset;
    }
  }
}

.is-translucent {
  backdrop-filter: saturate(180%) blur(1.25rem);
  background-color: rgba($color-white--100, 0.8);
  border-top-color: transparent;
}
