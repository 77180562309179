@import '../../../styles/index';

.base {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  color: currentColor;
  display: flex;
  gap: 0.25rem;
  padding: 0;

  &.is-enabled {
    cursor: pointer;
  }
}

.chevron {
  color: $color-red--100;
  transform: scaleY(1);
  transition: transform $duration-default;

  .is-active & {
    transform: scaleY(-1);
  }

  .is-inverted & {
    color: currentColor;
  }
}
