@import '../../styles/index';

.base {
  display: flex;
  position: relative;

  .count-number {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: $color-surrogate--100;
    color: white;
    font-size: $font-size-10;
    font-weight: $font-weight-medium;
  }
}
