@import '../../../styles/index';

.base {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  color: currentColor;
  display: inline-flex;
  gap: 0.5rem;

  &.is-active {
    color: $color-surrogate--100;
  }

  &.is-interactive {
    cursor: pointer;
  }
}

.title {
  display: none;

  @include media-from(md) {
    display: block;
  }
}
