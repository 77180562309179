@import '../../../styles/index';

.base {
  align-items: center;
  color: $color-surrogate--100;
  display: flex;
  gap: 0.6875rem;
  height: 100%;
  left: 0;
  justify-content: space-between;
  opacity: 0;
  padding: 1rem 0.6875rem 1rem 1rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity $duration-default 0s;
  width: 100%;

  &.is-active {
    opacity: 1;
    pointer-events: all;
    transition: opacity $duration-default $duration-default;
  }

  &.is-inverted {
    color: $color-white--100;
  }

  @include media-from(lg) {
    padding: 0 1.25rem;
  }
}

.flex-shifter {
  width: 3.125rem;

  @include media-to(lg) {
    display: none;
  }
}

.search-bar {
  align-items: center;
  background-color: $color-surrogate--05;
  border-radius: 100px;
  color: inherit;
  display: flex;
  height: 3.125rem;
  max-width: 600px;
  padding: 0 1.25rem;
  position: relative;
  width: 100%;

  .is-inverted & {
    background-color: rgba($color-white--100, 0.16);
  }
}

.search-input {
  background-color: transparent;
  border: none;
  border-radius: 100px;
  color: inherit;
  left: 0;
  line-height: 3.125rem;
  outline: none;
  padding: 0 1.25rem 0 3.25rem;
  position: absolute;
  top: 0;
  width: 100%;

  &::placeholder {
    color: $color-surrogate--40;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &:focus-visible {
    box-shadow: $shadow-input-search-focus;
  }

  .is-inverted & {
    &::placeholder {
      color: rgba($color-white--100, 0.8);
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px rgba($color-white--100, 0.24),
        0 0 0 2px inset $color-white--100;
    }
  }
}

.button {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0.375rem;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 3.125rem;
  justify-content: center;
  outline: none;
  padding: 0;
  transition: background-color 0.2s ease;
  width: 3.125rem;

  &:focus-visible {
    outline: 2px solid $color-surrogate--100;
  }

  &:focus-visible,
  &:hover {
    background-color: rgba($color-surrogate--100, 0.05);
  }

  .is-inverted &:hover {
    background-color: rgba($color-white--100, 0.16);
  }
}

.suggestions-box {
  background-color: $color-white--100;
  color: $color-black--100;
  height: calc(100vh - max(var(--mobile-offset), 4.5rem));
  left: 0;
  opacity: 0;
  padding: 0 1.25rem;
  pointer-events: none;
  position: absolute;
  top: 4.5rem;
  transition: all $duration-default 0s;
  width: 100%;
  z-index: $index-search-content;

  &.is-active {
    opacity: 1;
    pointer-events: all;
    transition: all $duration-default $duration-default;
  }

  @include media-from(lg) {
    border-radius: 4px;
    box-shadow: $shadow-small;
    height: auto;
    left: 50%;
    margin: 0 auto;
    max-width: 600px;
    padding: 1rem;
    top: calc(6rem + var(--offset) + 1.5rem);
    transform: translateX(-50%) scale(0.975);

    &.is-active {
      transform: translateX(-50%) scale(1);
    }
  }
}

.suggestions-title {
  color: $color-surrogate--40;
  font-size: $font-size-14;
  font-weight: $font-weight-medium;
  padding: 0.5rem 0;

  @include media-from(lg) {
    padding: 0.5rem 1rem;
  }
}

.suggestions-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.suggestion {
  color: inherit;
  cursor: pointer;
  display: list-item;
  padding: 1rem 0;

  & + & {
    border-top: 1px solid $color-surrogate--10;

    @include media-from(lg) {
      border-top: none;
    }
  }

  @include media-from(lg) {
    border-radius: 4px;
    padding: 0.75rem 1rem;

    &:focus-visible {
      outline: 2px solid $color-surrogate--100;
    }

    &:hover {
      background-color: $color-surrogate--05 !important;
      color: $color-surrogate--100;
    }
  }
}
